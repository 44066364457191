var swiperHelper;

function swiperHelper(){
    let me = this;
    me.logo;
    me.swipers = {};

    me.init = (offset) => {
        me.logo = document.getElementById("tvsc-header-logo-img");
        me.breakpoint1000 = window.matchMedia('(min-width:1000px)');
        me.breakpoint1200 = window.matchMedia('(min-width:1200px)');
        me.breakpoint1600 = window.matchMedia('(min-width:1600px)');

        me.offset = 0;
        if(offset){
            me.offset = offset;
        }

        me.setupSwipers();
        me.initSwipers();

        window.addEventListener('MMSkinGo', () => {
            setTimeout(() => {me.updateSwipers();}, 1000);
        }, false);

        me.breakpoint1000.addListener(me.breakpointChecker);
        me.breakpoint1200.addListener(me.breakpointChecker);
        me.breakpoint1600.addListener(me.breakpointChecker);
    };

    me.breakpointChecker = () => {
        setTimeout(() => {me.updateSwipers();}, 300);
    };

    me.setupSwipers = () => {
        let swiperContainers = document.querySelectorAll(".tvsc-swiper-container");
        console.debug({swiperContainers});
        for(let i=0; i<swiperContainers.length; i++){
            let target = swiperContainers[i],
                name = target.dataset.swipername,
                type = target.dataset.swipertype,
                load = target.dataset.swiperload
                callback = target.dataset.swipercallback || false;

            me.swipers[name] = {
                "target": target,
                "type": type,
                "load": load,
                "callback": callback
            };

            console.debug(me.swipers[name]);
        }
        
        me.setSlides();
    };

    me.setSlides = () => {
        for(let name in me.swipers){
            let type = me.swipers[name].type;
            let load = me.swipers[name].load;

            switch(type){
                case "main":
                    me.setMainSlides(name);
                break;

                case "default":
                    me.setDefaultSlides(name);
                break;
            }
        }
    };

    me.setDefaultSlides = (name) => {
        me.swipers[name]["slides"] = me.getSlidesFromDOM(name);
    };

    me.getSlidesFromDOM = (name) => {
        var slides = [];
        let target = me.swipers[name].target;
        let slidesDOM = [].slice.call(target.querySelectorAll(":scope > .tvsc-swiper-slide"));
        for(var i=0; i<slidesDOM.length; i++){
            var slide = slidesDOM[i];
            slides.push(slide.outerHTML);
        }

        return slides;
    };

    me.initSwipers = () => {
        for(var name in me.swipers){
            if(me.swipers[name].load == "default"){
                var target = me.swipers[name].target;
                console.debug({target});
                me.swipers[name]["swiper"] = me.initSwiper(target);
            }
        }

        me.processLazySwipers();
        window.addEventListener('scroll', function() {
            me.processLazySwipers();
        });
        window.addEventListener('load', function() {
            me.processEmbeds();
        })
    };

    me.processLazySwipers = () => {
        for(var name in me.swipers){
            if(me.swipers[name].load == "lazy"){
                var target = me.swipers[name].target;
                if(me.toProcessLazySwipers(target) && !target.classList.contains('processed')){
                    me.swipers[name]["swiper"] = me.initSwiper(target);
                    target.classList.add('processed');
                }
            }
        }
    };

    me.toProcessLazySwipers = (element) => {
        var rect = element.getBoundingClientRect();
        var html = document.documentElement;
        return (
            (rect.top - me.offset) <= (window.innerHeight || html.clientHeight) && (rect.top + rect.height) >= 0
        );
    };

    me.setMainSlides = (name) =>{
        let swiper = me.swipers[name];

        if(swiper.swiper === undefined){
            swiper["swiper"] = me.initMainSwiper(swiper.target);
            swiper.target.classList.add("tvsc-swiper-dom-loaded");
        }
    };

    me.initSwiper = (target, loop) => {

        var swiper = new Swiper(target, {
            // breakpointsInverse: true,
            speed: 500,
            navigation: {
                nextEl: target.parentNode.querySelectorAll(':scope .tvsc-arrow-button-next'),
                prevEl: target.parentNode.querySelectorAll(':scope .tvsc-arrow-button-prev')
            },
            pagination: {
                el: target.parentNode.querySelectorAll(':scope .tvsc-swiper-pagination'),
                type: 'fraction'
            },
            loop: target.dataset.swiperloop ? target.dataset.swiperloop : false,
            slidesPerView: 1,
            // preloadImages: false,
            // lazy: {
            //     loadPrevNext: true,
            // },
            slidesPerGroup: 1,
            calculateHeight:true,
            autoHeight:true,
            simulateTouch: false,

            // observeParents: true,
            on: {
                init: function () { 
                    // in caso di embed iframe, attiviamo gli iframe solo in prima e seconda slide:
                    me.processEmbeds();
                    setTimeout(() => {
                        me.updateSwipers();
                        postTcfReady(initAdvSlides);
                    }, 1000);
                },
                slideChangeTransitionEnd: function () {
                    // al cambio slide avvenuto, attiviamo gli iframe in slide corrente e corrente+1:
                    me.processEmbeds();

                    

                    // tracciami ste gallery!
                    if (this.realIndex > 0) {
                        let ga_path = document.location.pathname+'#image_'+parseInt(this.realIndex+1);
                        gtag('config', 'G-1Q0XXZE7V2', {
                            'anonymize_ip': true,
                            'page_path': ga_path
                        });
                    }

                    setTimeout(() => {me.updateSwipers();}, 1000);
                }
            },
        });
        console.debug({swiper});
        return swiper;
    };

    me.processEmbeds = () => {

        let cur = document.querySelectorAll('.swiper-slide-active *[data-src], .swiper-slide-next *[data-src]');
        Array.from(cur).forEach( function(e, i) {
            if (e && e.getAttribute('data-src') !== null) { 
                e.src = e.getAttribute('data-src');
                e.removeAttribute('data-src');
            }
        });
        // console.debug('Loading embedded content in next slide...');
    }

    me.getSwipers = function(){
        console.debug(me.swipers);
        return me.swipers;
    };

    me.updateSwipers = () => {
        for(const i in me.swipers){
            me.swipers[i].swiper.update();
        }
    };
}
swiperHelper = new swiperHelper();
swiperHelper.init();




/* gallery carousel - swiper 
========================================= */

let InterstitialSlides = null;
let AdvSlides = [];

const initAdvSlides = () => {
    let swiIMG = Object.values(swiperHelper.getSwipers())[0].swiper;

    if (device != "desktop" && !InterstitialSlides) {

        if (document.querySelector('.tvsc-swiper-container') !== null) {
            setTimeout(function(){
                console.debug('------- swiper: ');
                
                swiIMG.on('slideNextTransitionStart', function (data) {
                    GallerySwiperAction('>>');
                });

                swiIMG.on('slidePrevTransitionStart', function (data) {
                    GallerySwiperAction('<<');
                });

            },5000);
        }

    } /* else if (device == 'desktop') {
        swiIMG.removeSlide(2);
    } */

    InterstitialSlides = true;

}

let slideTransitions = 0;
const GallerySwiperAction = (dir) => {

    const swiIMG = Object.values(swiperHelper.getSwipers())[0].swiper;

    let numslide = parseInt(swiIMG.realIndex + 1);

    slideTransitions++;

    // send pageview to GA
    // gtag('event', 'page_view', { 
    //     'page_title' : document.title,
    //     'page_path': document.location.pathname+'#image_'+numslide,
    //     'page_location': document.location.href+'#image_'+numslide,
    //     'dimension6': numslide,
    //     'dimension7': swiIMG.loopedSlides,
    //     'dimension8': 'classica',
    //     'dimension11': 'gallery'
    // });

    console.debug('Slide #' + numslide);

    if (device != "desktop") {
        let slotslide = numslide;

        if (dir == '<<') { 
            slotslide = numslide - 2; 
        }
        // console.debug(slotslide);

        if ( AdvSlides.includes(slotslide) ) {

            let added = document.querySelectorAll('.tvsc-swiper-interstitial');
            Array.from(added).forEach((el) => {
                el.removeAttribute('id');
                el.removeAttribute('style');
                el.removeAttribute('data-google-query-id');
                el.classList.add('tvsc-interstitial-sleeping');

                while (el.firstChild) {
                  el.removeChild(el.firstChild);
                }
            });
            if (mm_mobile_box2) {
                
                console.debug('Slide '+slotslide+': init adv now!');

                // add the ID to the correct div and request the ad
                let correctdiv = document.querySelector('.tvsc-swiper-interstitial[data-swiper-slide-index="'+slotslide+'"]:not(.swiper-slide-duplicate)');
                correctdiv.id=mm_mobile_box2.getSlotElementId();

            
                googletag.pubads().refresh([mm_mobile_box2]);

                googletag.pubads().addEventListener('impressionViewable', function(event) {
                    console.debug(event);
                    if(mm_mobile_box2 === event.slot){
                        swiIMG.update();
                    }
                });
            }

        }

    } else {
        //console.log(slideTransitions, slideTransitions%3, slideTransitions%3 == 0)
        if(slideTransitions%4 == 0) {
            // ...desktop: refresh adv in sidebar
            googletag.cmd.push(function() {
                googletag.pubads().refresh([mm_box1]);
            });
        }
    }
}


